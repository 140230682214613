<template>
  <el-dialog title="修改收货地址" :visible.sync="visible" size="tiny" width="550px" custom-class="change-address-dialog"
    :append-to-body="false" @close="close">
    <el-form ref="ruleForm" label-width="100px" :rules="rules" :model="ruleForm">
      <el-form-item label="粘贴地址：">
        <el-input type="textarea" :rows="3" placeholder="请粘贴或输入新的收货地址至此处,点击“识别”后将会自动填充至下方对应信息处" v-model="ruleForm.receiveInfo" clearable>
        </el-input>
        <div>
          <el-button size="small" type="primary" @click="handleParse(ruleForm.receiveInfo)">识别</el-button>
          <el-button v-if="ruleForm.receiveInfo" size="small" @click="handleClearAddress">清除</el-button>
        </div>
      </el-form-item>
      <div class="edit-address">
        <el-button size="small" type="text" @click="handleEditAddress">{{ disabled? '编辑': '完成' }}</el-button>
      </div>
      <el-form-item label="收货人：" prop="receiverName">
        <el-input v-if="disabled" v-model="ruleForm.receiverNameSecret" placeholder="请输入收货人" :disabled="disabled" clearable></el-input>
        <el-input v-else v-model="ruleForm.receiverName" placeholder="请输入收货人" :disabled="disabled" clearable></el-input>
      </el-form-item>
      <el-form-item label="手机号：" prop="receiverMobile">
        <el-input v-if="disabled" v-model="ruleForm.receiverMobileSecret" placeholder="请输入手机号" :disabled="disabled" clearable></el-input>
        <el-input v-else v-model="ruleForm.receiverMobile" placeholder="请输入手机号" :disabled="disabled" clearable></el-input>
      </el-form-item>
      <el-form-item label="所在地区：" prop="area">
        <el-cascader v-model="ruleForm.area" :props="siteOptions" placeholder="请选择"
        ref="cascaderAddr"
        :key="resetCascader"
        clearable :disabled="disabled" />
      </el-form-item>
      <el-form-item label="详细地址：" prop="address">
        <el-input v-if="disabled" type="textarea" :rows="2" placeholder="请输入详细地址" v-model="ruleForm.addressSecret" :disabled="disabled" clearable>
        </el-input>
        <el-input v-else type="textarea" :rows="2" placeholder="请输入详细地址" v-model="ruleForm.address" :disabled="disabled" clearable>
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handleConfirm('ruleForm')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'OrderAddress',
  props: {},
  data() {
        return {
            visible: false,
            ruleForm: {
                receiveInfo: '',
                receiverName: '',
                receiverNameSecret: '',
                receiverMobile: '',
                receiverMobileSecret: '',
                area: [],
                address: '',
                addressSecret: '',
            },
            siteOptions: {
                lazy: true,
                lazyLoad: (node, resolve)=> {
                  this.getRegionList(node, resolve);
                },
                checkStrictly: true,
                value: 'regionCode',
                label: 'regionName',
                children: 'children'
            },
            rules: {
              receiverName: [{ required: true, trigger: 'change', message: '请输入收货人' }],
              receiverMobile: [{ required: true, trigger: 'change', message: '请输入手机号' }],
              area: [{ required: true, trigger: 'change', message: '请选择' }],
              address: [{ required: true, trigger: 'change', message: '请输入详细地址' }],
            },
            disabled: false,
            row: {},
            resetCascader: 1,
        }
    },
  methods: {
    handleConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        const {receiverName, receiverMobile, area, address} = this.ruleForm
        const areaTextList = this.$refs.cascaderAddr.inputValue.split(this.$refs.cascaderAddr.separator)
        this.$axios({
          method: 'post',
          url: this.$api.order.modifyReceive,
          data: {
            newReceiveInfo: {
              receiverName,
              receiverMobile,
              provinceName: areaTextList[0],
              provinceCode: area[0],
              cityName: areaTextList[1],
              cityCode: area[1],
              districtName: areaTextList[2],
              districtCode: area[2],
              townName: areaTextList[3],
              townCode: area[3],
              address,
            },
            orderId: this.row.tradeOrderVo.orderId,
          }
        }).then((res) => {
          if (res.code !== 0) return;
          this.$message.success('修改成功');
          this.close();
          this.$emit('refreshList');
        }).catch(err=>{
          console.log(err);
        });
      })
    },
    // 懒加载路由
    getRegionList(node, resolve) {
      const { data, level } = node;
      this.$axios
        .get(this.$api.commonNew.getChildRegion, {
          params: {
            regionCode: data ? data.regionCode : 0,
          },
        })
        .then((res) => {
          const nodes = res.data.map((item) => ({
            ...item,
            leaf: level > 2,
          }));
          resolve(nodes);
        });
    },
    close() {
      this.visible = false;
      this.ruleForm = Object.assign({}, this.$options.data().ruleForm);
      this.$refs.ruleForm.clearValidate();
    },
    open(item) {
      this.visible = true;
      this.disabled = true;
      const { receiverName, mobile, province, provinceCode, city, cityCode, district, districtCode, town, townCode, address } = item.tradeOrderVo
      let param = `${receiverName||''} ${mobile||''} ${province||''}${city||''}${district||''}${town||''}${address||''}`
      this.handleParse(param, true)
      this.row = item;
    },
    // 点击编辑
    handleEditAddress() {
      this.disabled = !this.disabled;
    },
    // 解析地址
    handleParse(param, disable) {
      if(!param){
        this.$message.warning('请粘贴或输入新的收货地址');
        return;
      }
      this.disabled = Boolean(disable);
      this.$axios({
        method: 'get',
        url: this.$api.order.receiveAnalysis,
        params: {
          receiveInfo: param,
        }
      }).then((res) => {
        if (res.code !== 0) return;
        // 解析后的地址
        const {receiverName, receiverMobile, provinceName,provinceCode, cityName, cityCode, districtName, districtCode, townName, townCode, address} = res.data;
        let areaList = [];
        if(provinceCode && cityCode && districtCode && townCode){
          areaList = [provinceCode, cityCode, districtCode, townCode]
        }else if(provinceCode && cityCode && districtCode){
          areaList = [provinceCode, cityCode, districtCode]
        }else if(provinceCode && cityCode){
          areaList = [provinceCode, cityCode]
        }else if(provinceCode){
          areaList = [provinceCode]
        }
        this.resetCascader++;
        this.ruleForm = Object.assign({}, this.ruleForm, {
          receiverName: receiverName,
          receiverNameSecret: receiverName? receiverName.substr(0,1)+Array(receiverName.length-1).fill('*').join(''): '', // 密文
          receiverMobile: receiverMobile,
          receiverMobileSecret: receiverMobile? receiverMobile.substr(0,3)+'****'+receiverMobile.substr(7): '', // 密文
          provinceName: provinceName,
          provinceCode: provinceCode,
          cityCode: cityName,
          cityCode: cityCode,
          districtName: districtName,
          districtCode: districtCode,
          townName: townName,
          townCode: townCode,
          address: address,
          addressSecret: address? Array(address.length).fill("*").join(''): '', // 密文
          area: areaList
        });
      }).catch(err => {
        console.log(err);
      });
    },
    // 清空输入
    handleClearAddress() {
      this.ruleForm.receiveInfo = '';
    },
  }
}
</script>
<style>
.change-address-dialog .edit-address {
    text-align: right;
}
.change-address-dialog .el-cascader {
  width: 100%;
}
.change-address-dialog .el-dialog__body {
  padding: 30px 30px 30px 20px;
}
</style>