var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "div",
        [
          _c(
            "SearchPanel",
            {
              attrs: { flex: "", "show-btn": "", size: "mini" },
              on: { getList: _vm.onSearch },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v(" 下单时间 ")]),
                  _c(
                    "el-date-picker",
                    {
                      attrs: {
                        type: "datetimerange",
                        size: "mini",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "value-format": "timestamp",
                        clearable: false,
                      },
                      model: {
                        value: _vm.dateRange,
                        callback: function ($$v) {
                          _vm.dateRange = $$v
                        },
                        expression: "dateRange",
                      },
                    },
                    [_vm._v(" > ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v(" 订单编号 ")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入订单编号",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.orderNo,
                      callback: function ($$v) {
                        _vm.orderNo = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "orderNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("订单状态")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "mini",
                        placeholder: "请选择",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.orderStatus,
                        callback: function ($$v) {
                          _vm.orderStatus = $$v
                        },
                        expression: "orderStatus",
                      },
                    },
                    _vm._l(Object.keys(_vm.ORDER_STATUS_MAP), function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: {
                          label: _vm.ORDER_STATUS_MAP[item],
                          value: item,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("售后状态")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "mini",
                        placeholder: "请选择",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.refundStatus,
                        callback: function ($$v) {
                          _vm.refundStatus = $$v
                        },
                        expression: "refundStatus",
                      },
                    },
                    _vm._l(Object.keys(_vm.REFUND_STATUS_MAP), function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: {
                          label: _vm.REFUND_STATUS_MAP[item],
                          value: item,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v(" 物流单号 ")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入完整物流单号",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.logisticNo,
                      callback: function ($$v) {
                        _vm.logisticNo =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "logisticNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v(" 拼团类型 ")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "mini",
                        placeholder: "请选择",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.groupType,
                        callback: function ($$v) {
                          _vm.groupType = $$v
                        },
                        expression: "groupType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "常规拼团", value: 1 },
                      }),
                      _c("el-option", {
                        attrs: { label: "凑单拼团", value: 2 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v(" 拼团ID ")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入拼团ID",
                      size: "mini",
                      clearable: "",
                      type: "number",
                    },
                    model: {
                      value: _vm.teamId,
                      callback: function ($$v) {
                        _vm.teamId = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "teamId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v(" 店铺名称 ")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入关键字",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.storeName,
                      callback: function ($$v) {
                        _vm.storeName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "storeName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v(" 商品ID ")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入完整商品ID",
                      size: "mini",
                      clearable: "",
                      type: "number",
                    },
                    model: {
                      value: _vm.goodsId,
                      callback: function ($$v) {
                        _vm.goodsId = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "goodsId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v(" 商品名称 ")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入关键字",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.goodsName,
                      callback: function ($$v) {
                        _vm.goodsName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "goodsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v(" 收件人名称 ")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入完整收件人名称",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.receiverName,
                      callback: function ($$v) {
                        _vm.receiverName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "receiverName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v(" 收件人手机号 ")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入收件人手机号",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.mobile,
                      callback: function ($$v) {
                        _vm.mobile = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("结算状态")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "mini",
                        placeholder: "请选择",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.orderSettleStatus,
                        callback: function ($$v) {
                          _vm.orderSettleStatus = $$v
                        },
                        expression: "orderSettleStatus",
                      },
                    },
                    _vm._l(
                      Object.keys(_vm.ORDER_SETTLE_STATUS),
                      function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: {
                            label: _vm.ORDER_SETTLE_STATUS[item],
                            value: item,
                          },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v(" 是否全部发货 ")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "mini",
                        placeholder: "请选择",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.isAllDelivery,
                        callback: function ($$v) {
                          _vm.isAllDelivery = $$v
                        },
                        expression: "isAllDelivery",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: "1" } }),
                      _c("el-option", { attrs: { label: "否", value: "0" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v(" 供应链类型 ")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "mini",
                        placeholder: "请选择",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.supplyType,
                        callback: function ($$v) {
                          _vm.supplyType = $$v
                        },
                        expression: "supplyType",
                      },
                    },
                    [_c("el-option", { attrs: { label: "直发", value: 1 } })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v(" 店铺优惠券ID: ")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入优惠券ID",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.storeCouponId,
                      callback: function ($$v) {
                        _vm.storeCouponId =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "storeCouponId",
                    },
                  }),
                ],
                1
              ),
              _vm.isMemberStore
                ? _c(
                    "div",
                    [
                      _c("p", [_vm._v(" 平台 ")]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "mini",
                            placeholder: "请选择",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.projectCode,
                            callback: function ($$v) {
                              _vm.projectCode = $$v
                            },
                            expression: "projectCode",
                          },
                        },
                        _vm._l(_vm.platformConfigList, function (item, i) {
                          return _c("el-option", {
                            key: i,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { slot: "button-right", size: "mini" },
                  on: { click: _vm.resetSearch },
                  slot: "button-right",
                },
                [_vm._v(" 重 置 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    slot: "button-right",
                    type: "primary",
                    size: "mini",
                  },
                  on: { click: _vm.exportTable },
                  slot: "button-right",
                },
                [_vm._v(" 导 出 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "overflow-x": "auto" } },
            [
              _c(
                "el-row",
                { staticClass: "order-header" },
                [
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v(" 商品信息 ")]),
                  _c("el-col", { attrs: { span: 1 } }, [_vm._v(" 数量 ")]),
                  _c("el-col", { attrs: { span: 1 } }, [
                    _vm._v(" 商品总价（元） "),
                  ]),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _vm._v(" 优惠金额（元） "),
                  ]),
                  _vm.isMemberStore
                    ? _c("el-col", { attrs: { span: 1 } }, [
                        _vm._v(" 会员卡支付 "),
                      ])
                    : _vm._e(),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _vm._v(" 买家实付（元） "),
                  ]),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _vm._v(" 商家实收（元） "),
                  ]),
                  _vm.isMemberStore
                    ? _c("el-col", { attrs: { span: 1 } }, [_vm._v(" 供应商 ")])
                    : _vm._e(),
                  _c("el-col", { attrs: { span: 2 } }, [_vm._v(" 买家信息 ")]),
                  _c("el-col", { attrs: { span: 1 } }, [_vm._v(" 收件人 ")]),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _vm._v(" 确认收货时间 "),
                  ]),
                  _c("el-col", { attrs: { span: 1 } }, [
                    _vm._v(" 订单/售后状态 "),
                  ]),
                  _c("el-col", { attrs: { span: 2 } }, [_vm._v(" 物流单号 ")]),
                  _c("el-col", { attrs: { span: 1 } }, [_vm._v(" 结算状态 ")]),
                  _c("el-col", { attrs: { span: 1 } }, [_vm._v(" 操作 ")]),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "order-body",
                  attrs: { "element-loading-text": "拼命加载中" },
                },
                [
                  _vm.tableData && _vm.tableData.length === 0
                    ? _c("div", { staticClass: "empty" }, [
                        _vm._v(" 暂无数据 "),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.tableData, function (order, index) {
                    return _c(
                      "div",
                      { key: order.orderId, staticClass: "order" },
                      [
                        _c("div", { staticClass: "order-id" }, [
                          _c(
                            "div",
                            [
                              _c("span", [
                                _vm._v(
                                  "订单编号：" +
                                    _vm._s(order.tradeOrderVo.orderNo)
                                ),
                              ]),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClipboard(
                                        $event,
                                        order.tradeOrderVo.orderNo
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 复制 ")]
                              ),
                              order.tradeOrderVo.groupPurId
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "margin-left": "60px",
                                      },
                                    },
                                    [_vm._v(" 社区团购 ")]
                                  )
                                : _vm._e(),
                              order.tradeOrderVo.teamId
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "padding-left": "20px" },
                                      },
                                      [
                                        _vm._v(
                                          "拼团ID：" +
                                            _vm._s(order.tradeOrderVo.teamId)
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClipboard(
                                              $event,
                                              order.tradeOrderVo.teamId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 复制 ")]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm.isMemberStore
                            ? _c(
                                "p",
                                { staticStyle: { "line-height": "28px" } },
                                [
                                  _vm._v(
                                    " 平台：" +
                                      _vm._s(
                                        order.tradeOrderVo.projectCodeExplain
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("p", { staticStyle: { "line-height": "28px" } }, [
                            _vm._v(
                              " 订单状态：" +
                                _vm._s(
                                  _vm.ORDER_STATUS_MAP[
                                    order.tradeOrderVo.orderStatus
                                  ]
                                ) +
                                " "
                            ),
                            order.tradeOrderVo.autoCancelWaitConfirmTime > 0
                              ? _c("span", { staticClass: "countdown" }, [
                                  _vm._v(
                                    "(请尽快接单，剩" +
                                      _vm._s(_vm.handleTime(index)) +
                                      "订单将自动取消)"
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "p",
                            { staticStyle: { "line-height": "28px" } },
                            [
                              order.tradeOrderVo.orderStatus == 4 &&
                              order.tradeOrderVo.modifyReceiveStatus == 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "small",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleAuditAddress(order)
                                        },
                                      },
                                    },
                                    [_vm._v(" 改地址待处理 ")]
                                  )
                                : _vm._e(),
                              order.tradeOrderVo.orderStatus == 4 &&
                              order.tradeOrderVo.modifyReceiveStatus == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(" 改地址已同意 "),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("p", { staticStyle: { "line-height": "28px" } }, [
                            _vm._v(
                              " 下单时间：" +
                                _vm._s(
                                  _vm
                                    .dayjs(order.tradeOrderVo.createTime)
                                    .format("YYYY-MM-DD HH:mm:ss")
                                ) +
                                " "
                            ),
                          ]),
                          _c(
                            "div",
                            [
                              (+order.tradeOrderVo.orderStatus === 4 ||
                                +order.tradeOrderVo.orderStatus === 6) &&
                              +order.tradeOrderVo.supplyType !== 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled:
                                          +order.tradeOrderVo.orderStatus ===
                                            6 &&
                                          !!order.tradeOrderVo.lastShippingTime,
                                        type: "primary",
                                        size: "mini",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDeliverGoods(order)
                                        },
                                      },
                                    },
                                    [_vm._v(" 发货 ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        order.tradeOrderGoodsVoList &&
                        order.tradeOrderGoodsVoList.length === 0
                          ? _c("el-row", { staticClass: "goods-table" }, [
                              _vm._v(" 暂无商品 "),
                            ])
                          : _vm._e(),
                        _vm._l(
                          order.tradeOrderGoodsVoList,
                          function (goods, goodsIndex) {
                            return _c(
                              "el-row",
                              { key: goodsIndex, staticClass: "goods-table" },
                              [
                                _c("el-col", { attrs: { span: 4 } }, [
                                  _c("div", { staticClass: "goods-card" }, [
                                    _c("img", {
                                      attrs: {
                                        src: goods.goodsImage.split(",")[0],
                                      },
                                    }),
                                    _c("div", { staticClass: "content" }, [
                                      _c("p", [
                                        _vm._v(_vm._s(goods.goodsName)),
                                      ]),
                                      _c("p", { staticClass: "sku" }, [
                                        _vm._v(
                                          " ID:" + _vm._s(goods.goodsId) + " "
                                        ),
                                      ]),
                                      _c("p", { staticClass: "sku" }, [
                                        _vm._v(
                                          " 规格：" +
                                            _vm._s(goods.skuName) +
                                            " "
                                        ),
                                      ]),
                                      _vm.ORDER_TYPE[goods.type]
                                        ? _c("p", { staticClass: "sku" }, [
                                            _vm._v(
                                              " 商品类型：" +
                                                _vm._s(
                                                  _vm.ORDER_TYPE[goods.type]
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 1 } }, [
                                  _vm._v(" " + _vm._s(goods.sellCount) + " "),
                                ]),
                                _c("el-col", { attrs: { span: 1 } }, [
                                  _vm._v(" " + _vm._s(goods.sellTotal) + " "),
                                ]),
                                _c("el-col", { attrs: { span: 2 } }, [
                                  _c("p", { staticClass: "fee" }, [
                                    _vm._v(
                                      " 商家券优惠：" +
                                        _vm._s(
                                          order.tradeOrderVo
                                            .useExchangeCoupon === 1
                                            ? "抵扣商品"
                                            : goods.discountFee
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("p", { staticClass: "fee" }, [
                                    _vm._v(
                                      " 平台券优惠：" +
                                        _vm._s(goods.couponFee) +
                                        " "
                                    ),
                                  ]),
                                  goods.fullMinusFee != 0
                                    ? _c("p", { staticClass: "fee" }, [
                                        _vm._v(
                                          " 满减优惠：" +
                                            _vm._s(goods.fullMinusFee) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("p", { staticClass: "fee" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.GOODS_TYPE_MAP[goods.goodsType]
                                        ) +
                                        "活动优惠：" +
                                        _vm._s(goods.promFee) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _vm.isMemberStore
                                  ? _c("el-col", { attrs: { span: 1 } }, [
                                      _c("p", [
                                        _vm._v(_vm._s(goods.membershipCardFee)),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _c("el-col", { attrs: { span: 2 } }, [
                                  _c("p", [
                                    _vm._v(" " + _vm._s(goods.payment) + " "),
                                  ]),
                                  _c("p", { staticClass: "fee" }, [
                                    _vm._v(
                                      " 含运费：" + _vm._s(goods.postFee) + " "
                                    ),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 2 } }, [
                                  _c("p", [
                                    _vm._v(
                                      " " + _vm._s(goods.supplierPayment) + " "
                                    ),
                                  ]),
                                  _c("p", { staticClass: "fee" }, [
                                    _vm._v(
                                      " 含运费：" + _vm._s(goods.postFee) + " "
                                    ),
                                  ]),
                                ]),
                                _vm.isMemberStore
                                  ? _c("el-col", { attrs: { span: 1 } }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            goods.belongSupplierName
                                              ? goods.belongSupplierName
                                              : "/"
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("el-col", { attrs: { span: 2 } }, [
                                  _c("img", {
                                    attrs: {
                                      src: order.tradeOrderVo.headPic,
                                      width: "25px",
                                      height: "25px",
                                    },
                                  }),
                                  _c("p", [
                                    _vm._v(_vm._s(order.tradeOrderVo.nickname)),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(order.tradeOrderVo.userMobile)
                                    ),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 1 } }, [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(order.tradeOrderVo.receiverName)
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        order.tradeOrderVo.mobile || "暂无"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 2 } }, [
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          order.tradeOrderVo.confirmTime
                                            ? _vm
                                                .dayjs(
                                                  order.tradeOrderVo.confirmTime
                                                )
                                                .format("YYYY-MM-DD HH:mm:ss")
                                            : "未确认"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 1 } }, [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.GOODS_STATUS_MAP[
                                          goods.subOrderStatus
                                        ] || "暂无"
                                      )
                                    ),
                                  ]),
                                  _c("p", { staticClass: "refund" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.REFUND_STATUS_MAP[
                                            goods.refundStatus
                                          ]
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 2 } }, [
                                  _c("p", [
                                    _vm._v(_vm._s(goods.logisticNo || "暂无")),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 1 } }, [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.ORDER_SETTLE_STATUS[
                                          order.tradeOrderVo.settlementStatus
                                        ] || "暂无"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 1 } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDetail(
                                              order.tradeOrderVo.orderType,
                                              order.tradeOrderVo.orderId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 查看详情 ")]
                                    ),
                                    _c(
                                      "div",
                                      [
                                        [1, 4].includes(
                                          order.tradeOrderVo.orderStatus
                                        )
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleChangeAddress(
                                                      order
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 修改地址 ")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm.tableData && _vm.tableData.length > 0
            ? _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": [10, 20, 30, 50],
                  "page-size": _vm.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.count,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("order-address", {
        ref: "orderAddress",
        on: { refreshList: _vm.getSearchList },
      }),
      _c("DeliverOrderDialog", {
        attrs: { "order-data": _vm.orderData, type: "add" },
        on: { success: _vm.deliverSuccess },
        model: {
          value: _vm.showDeliverModel,
          callback: function ($$v) {
            _vm.showDeliverModel = $$v
          },
          expression: "showDeliverModel",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }