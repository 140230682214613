<template>
  <div class="main">
    <div>
      <SearchPanel
        flex
        show-btn
        size="mini"
        @getList="onSearch"
      >
        <div>
          <p>
            下单时间
          </p>
          <el-date-picker
            v-model="dateRange"
            type="datetimerange"
            size="mini"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            :clearable="false"
          >
            >
          </el-date-picker>
        </div>
        <div>
          <p>
            订单编号
          </p>
          <el-input
            v-model.trim="orderNo"
            placeholder="请输入订单编号"
            size="mini"
            clearable
          />
        </div>

        <div>
          <p>订单状态</p>
          <el-select
            v-model="orderStatus"
            size="mini"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              v-for="item in Object.keys(ORDER_STATUS_MAP)"
              :key="item"
              :label="ORDER_STATUS_MAP[item]"
              :value="item"
            />
          </el-select>
        </div>

        <div>
          <p>售后状态</p>
          <el-select
            v-model="refundStatus"
            size="mini"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              v-for="item in Object.keys(REFUND_STATUS_MAP)"
              :key="item"
              :label="REFUND_STATUS_MAP[item]"
              :value="item"
            />
          </el-select>
        </div>

        <div>
          <p>
            物流单号
          </p>
          <el-input
            v-model.trim="logisticNo"
            placeholder="请输入完整物流单号"
            size="mini"
            clearable
          />
        </div>
        <div>
          <p>
            拼团类型
          </p>
          <el-select
            v-model="groupType"
            size="mini"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              label="常规拼团"
              :value="1"
            />
            <el-option
              label="凑单拼团"
              :value="2"
            />
          </el-select>
        </div>
        <div>
          <p>
            拼团ID
          </p>
          <el-input
            v-model.trim="teamId"
            placeholder="请输入拼团ID"
            size="mini"
            clearable
            type="number"
          />
        </div>
        <div>
          <p>
            店铺名称
          </p>
          <el-input
            v-model.trim="storeName"
            placeholder="请输入关键字"
            size="mini"
            clearable
          />
        </div>

        <div>
          <p>
            商品ID
          </p>
          <el-input
            v-model.trim="goodsId"
            placeholder="请输入完整商品ID"
            size="mini"
            clearable
            type="number"
          />
        </div>

        <div>
          <p>
            商品名称
          </p>
          <el-input
            v-model.trim="goodsName"
            placeholder="请输入关键字"
            size="mini"
            clearable
          />
        </div>

        <div>
          <p>
            收件人名称
          </p>
          <el-input
            v-model.trim="receiverName"
            placeholder="请输入完整收件人名称"
            size="mini"
            clearable
          />
        </div>

        <div>
          <p>
            收件人手机号
          </p>
          <el-input
            v-model.trim="mobile"
            placeholder="请输入收件人手机号"
            size="mini"
            clearable
          />
        </div>

        <div>
          <p>结算状态</p>
          <el-select
            v-model="orderSettleStatus"
            size="mini"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              v-for="item in Object.keys(ORDER_SETTLE_STATUS)"
              :key="item"
              :label="ORDER_SETTLE_STATUS[item]"
              :value="item"
            />
          </el-select>
        </div>

        <div>
          <p>
            是否全部发货
          </p>
          <el-select
            v-model="isAllDelivery"
            size="mini"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              label="是"
              :value="'1'"
            />
            <el-option
              label="否"
              :value="'0'"
            />
          </el-select>
        </div>
        <div>
          <p>
            供应链类型
          </p>
          <el-select
            v-model="supplyType"
            size="mini"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              label="直发"
              :value="1"
            />
          </el-select>
        </div>
        <div>
          <p>
            店铺优惠券ID:
          </p>
          <el-input
            v-model.trim="storeCouponId"
            placeholder="请输入优惠券ID"
            size="mini"
            clearable
          />
        </div>
        <div v-if="isMemberStore">
          <p>
            平台
          </p>
          <el-select
            v-model="projectCode"
            size="mini"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              v-for="(item,i) in platformConfigList"
              :key="i"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
        </div>
        <el-button
          slot="button-right"
          size="mini"
          @click="resetSearch"
        >
          重 置
        </el-button>

        <el-button
          slot="button-right"
          type="primary"
          size="mini"
          @click="exportTable"
        >
          导 出
        </el-button>
      </SearchPanel>
      <div
        style="overflow-x: auto"
      >
        <el-row class="order-header">
          <el-col :span="4">
            商品信息
          </el-col>
          <el-col :span="1">
            数量
          </el-col>
          <el-col :span="1">
            商品总价（元）
          </el-col>
          <el-col :span="2">
            优惠金额（元）
          </el-col>
          <el-col
            v-if="isMemberStore"
            :span="1"
          >
            会员卡支付
          </el-col>
          <el-col :span="2">
            买家实付（元）
          </el-col>
          <el-col :span="2">
            商家实收（元）
          </el-col>
          <el-col
            v-if="isMemberStore"
            :span="1"
          >
            供应商
          </el-col>
          <el-col :span="2">
            买家信息
          </el-col>
          <el-col :span="1">
            收件人
          </el-col>
          <el-col :span="2">
            确认收货时间
          </el-col>
          <el-col :span="1">
            订单/售后状态
          </el-col>
          <el-col :span="2">
            物流单号
          </el-col>
          <el-col :span="1">
            结算状态
          </el-col>
          <el-col :span="1">
            操作
          </el-col>
        </el-row>
        <div
          v-loading="loading"
          class="order-body"
          element-loading-text="拼命加载中"
        >
          <div
            v-if="tableData && tableData.length === 0"
            class="empty"
          >
            暂无数据
          </div>
          <div
            v-for="(order,index) in tableData"
            :key="order.orderId"
            class="order"
          >
            <div class="order-id">
              <div>
                <span>订单编号：{{ order.tradeOrderVo.orderNo }}</span>
                <el-button
                  type="text"
                  size="mini"
                  @click="handleClipboard($event, order.tradeOrderVo.orderNo)"
                >
                  复制
                </el-button>
                <div
                  v-if="order.tradeOrderVo.groupPurId"
                  style="color: red; margin-left: 60px;"
                >
                  社区团购
                </div>
                <template v-if="order.tradeOrderVo.teamId">
                  <span style="padding-left: 20px">拼团ID：{{ order.tradeOrderVo.teamId }}</span>
                  <el-button
                    type="text"
                    size="mini"
                    @click="handleClipboard($event, order.tradeOrderVo.teamId)"
                  >
                    复制
                  </el-button>
                </template>
              </div>
              <p
                v-if="isMemberStore"
                style="line-height: 28px"
              >
                平台：{{ order.tradeOrderVo.projectCodeExplain }}
              </p>
              <p style="line-height: 28px">
                订单状态：{{ ORDER_STATUS_MAP[order.tradeOrderVo.orderStatus] }}
                <span
                  v-if="order.tradeOrderVo.autoCancelWaitConfirmTime>0"
                  class="countdown"
                >(请尽快接单，剩{{ handleTime(index) }}订单将自动取消)</span>
              </p>
              <p style="line-height: 28px">
                <el-button
                  v-if="order.tradeOrderVo.orderStatus == 4 && order.tradeOrderVo.modifyReceiveStatus == 0"
                  size="small"
                  type="primary"
                  plain
                  @click="handleAuditAddress(order)"
                >
                  改地址待处理
                </el-button>
                <el-tag
                  v-if="order.tradeOrderVo.orderStatus == 4 && order.tradeOrderVo.modifyReceiveStatus == 1"
                  type="success"
                >
                  改地址已同意
                </el-tag>
              </p>
              <p style="line-height: 28px">
                下单时间：{{ dayjs(order.tradeOrderVo.createTime).format('YYYY-MM-DD HH:mm:ss') }}
              </p>
              <div>
                <el-button
                  v-if="(+order.tradeOrderVo.orderStatus === 4 || +order.tradeOrderVo.orderStatus === 6)
                    && +order.tradeOrderVo.supplyType !== 1"
                  :disabled="+order.tradeOrderVo.orderStatus === 6 && !!order.tradeOrderVo.lastShippingTime"
                  type="primary"
                  size="mini"
                  @click="onDeliverGoods(order)"
                >
                  发货
                </el-button>
              </div>
            </div>
            <el-row
              v-if="order.tradeOrderGoodsVoList && order.tradeOrderGoodsVoList.length === 0"
              class="goods-table"
            >
              暂无商品
            </el-row>
            <el-row
              v-for="(goods, goodsIndex) in order.tradeOrderGoodsVoList"
              :key="goodsIndex"
              class="goods-table"
            >
              <el-col :span="4">
                <div class="goods-card">
                  <img :src="goods.goodsImage.split(',')[0]" />
                  <div class="content">
                    <p>{{ goods.goodsName }}</p>
                    <p class="sku">
                      ID:{{ goods.goodsId }}
                    </p>
                    <p class="sku">
                      规格：{{ goods.skuName }}
                    </p>
                    <p
                      v-if="ORDER_TYPE[goods.type]"
                      class="sku"
                    >
                      商品类型：{{ ORDER_TYPE[goods.type] }}
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col :span="1">
                {{ goods.sellCount }}
              </el-col>
              <el-col :span="1">
                {{ goods.sellTotal }}
              </el-col>
              <el-col :span="2">
                <p class="fee">
                  商家券优惠：{{ order.tradeOrderVo.useExchangeCoupon === 1 ? '抵扣商品' : goods.discountFee }}
                </p>
                <p class="fee">
                  平台券优惠：{{ goods.couponFee }}
                </p>
                <p
                  v-if="goods.fullMinusFee != 0"
                  class="fee"
                >
                  满减优惠：{{ goods.fullMinusFee }}
                </p>
                <p class="fee">
                  {{ GOODS_TYPE_MAP[goods.goodsType] }}活动优惠：{{ goods.promFee }}
                </p>
              </el-col>
              <!-- 会员卡支付 -->
              <el-col
                v-if="isMemberStore"
                :span="1"
              >
                <p>{{ goods.membershipCardFee }}</p>
              </el-col>
              <el-col :span="2">
                <p>
                  {{ goods.payment }}
                </p>
                <p class="fee">
                  含运费：{{ goods.postFee }}
                </p>
              </el-col>
              <el-col :span="2">
                <p>
                  {{ goods.supplierPayment }}
                </p>

                <p class="fee">
                  含运费：{{ goods.postFee }}
                </p>
              </el-col>
              <!-- 供应商 -->
              <el-col
                v-if="isMemberStore"
                :span="1"
              >
                {{ goods.belongSupplierName ? goods.belongSupplierName : '/' }}
              </el-col>
              <!-- 买家信息 -->
              <el-col :span="2">
                <img
                  :src="order.tradeOrderVo.headPic"
                  width="25px"
                  height="25px"
                />
                <p>{{ order.tradeOrderVo.nickname }}</p>
                <p>{{ order.tradeOrderVo.userMobile }}</p>
              </el-col>
              <el-col :span="1">
                <p>{{ order.tradeOrderVo.receiverName }}</p>
                <p>{{ order.tradeOrderVo.mobile || '暂无' }}</p>
              </el-col>
              <el-col :span="2">
                <p>
                  {{ order.tradeOrderVo.confirmTime ? dayjs(order.tradeOrderVo.confirmTime).format('YYYY-MM-DD HH:mm:ss')
                    : '未确认'
                  }}
                </p>
              </el-col>
              <el-col :span="1">
                <p>{{ GOODS_STATUS_MAP[goods.subOrderStatus] || '暂无' }}</p>
                <p class="refund">
                  {{ REFUND_STATUS_MAP[goods.refundStatus] }}
                </p>
              </el-col>
              <el-col :span="2">
                <p>{{ goods.logisticNo || '暂无' }}</p>
              </el-col>
              <el-col :span="1">
                <p>{{ ORDER_SETTLE_STATUS[order.tradeOrderVo.settlementStatus] || '暂无' }}</p>
              </el-col>
              <el-col :span="1">
                <el-button
                  type="text"
                  @click="handleDetail(order.tradeOrderVo.orderType, order.tradeOrderVo.orderId)"
                >
                  查看详情
                </el-button>
                <div>
                  <el-button
                    v-if="[1,4].includes(order.tradeOrderVo.orderStatus)"
                    type="text"
                    @click="handleChangeAddress(order)"
                  >
                    修改地址
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <el-pagination
        v-if="tableData && tableData.length > 0"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <order-address
      ref="orderAddress"
      @refreshList="getSearchList"
    />
    <!-- 订单发货弹窗 -->
    <DeliverOrderDialog
      v-model="showDeliverModel"
      :order-data="orderData"
      type="add"
      @success="deliverSuccess"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';

import { set } from 'lodash';
import DeliverOrderDialog from './delivery/components/deliver-order-dialog.vue';
import clipboard from '@/utils/clipboard';
import {
  REFUND_STATUS_MAP,
  ORDER_STATE_ARR,
  AFTER_SALE_ARR,
  GOODS_STATUS_MAP,
  ORDER_STATUS_MAP,
  ORDER_SETTLE_STATUS,
  GOODS_TYPE_MAP,
  ORDER_TYPE,
} from './normals';
import OrderAddress from './orderAddress.vue';
import { platformConfigList } from '@/utils/projectConfig';
import { downloadFile } from '@/utils/download';

export default {
  components: {
    OrderAddress,
    DeliverOrderDialog,
  },
  data() {
    return {
      platformConfigList,
      isMemberStore: false, // 农博优选商家
      loading: false,
      dayjs,
      REFUND_STATUS_MAP,
      GOODS_STATUS_MAP,
      ORDER_STATUS_MAP,
      ORDER_SETTLE_STATUS,
      ORDER_TYPE,
      GOODS_TYPE_MAP,
      orderSates: ORDER_STATE_ARR,
      afterSaleStates: AFTER_SALE_ARR,
      beginTime: '', // 查询-下单时间
      endTime: '',
      goodsId: '', // 查询-商品ID
      goodsName: '', // 查询-商品名称
      mobile: '', // 查询-收件人手机号
      orderNo: '', // 查询-订单编号
      orderStatus: null, // 查询-订单状态
      receiverName: '', // 查询-收件人名称
      storeName: '', // 查询-店铺名称
      refundStatus: '', // 查询-售后状态
      logisticNo: '', // 查询-物流单号
      orderSettleStatus: '', // 结算状态
      isAllDelivery: '', // 是否全部发货
      supplyType: '', // 是否直发
      teamId: '', // 拼团id
      storeCouponId: '', // 优惠券ID
      projectCode: '',
      groupType: '', // 拼团类型
      currentPage: 1, // 分页
      pageSize: 10,
      // dateRange: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 30), new Date()],
      dateRange: [new Date().getTime() - 3600 * 1000 * 24 * 30, new Date().getTime()],
      tableData: [],
      count: 0,
      // 发货弹窗数据
      showDeliverModel: false,
      orderData: null,
      timer: null,
    };
  },
  created() {
    try {
      this.isMemberStore = JSON.parse(localStorage.getItem('userInfo')).isMemberStore;
    } catch (err) {
    }
  },
  async mounted() {
    this.handleClearInterval();
    await this.getSearchList();
    this.showCountdown();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    handleClearInterval() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    showCountdown() {
      // 存在待确认取消的民宿订单，进行倒计时
      const poll = this.tableData.some((item) => item.tradeOrderVo?.autoCancelWaitConfirmTime);
      if (poll) {
        this.timer = setInterval(() => {
          this.tableData.forEach((item) => {
            if (item.tradeOrderVo.autoCancelWaitConfirmTime) {
              const currentTime = dayjs().valueOf();
              const remainTime = item.tradeOrderVo.countdownEndTime - currentTime;
              if (remainTime > 0) {
                this.$set(item.tradeOrderVo, 'autoCancelWaitConfirmTime', remainTime - 1000);
              } else {
                this.$set(item.tradeOrderVo, 'autoCancelWaitConfirmTime', 0);
                setTimeout(async () => {
                  if (!item.tradeOrderVo.refresh) {
                    await this.getSearchList('refresh');
                    // 设置refresh,防止定时器一直执行而重复请求接口，tableData中的数据共用一个定时器
                    this.$set(item.tradeOrderVo, 'refresh', true);
                  }
                }, 2000);
                return;
              }
            }
          });
        }, 1000);
      }
    },
    handleTime(index) {
      const value = +this.tableData[index].tradeOrderVo.autoCancelWaitConfirmTime;
      const time = dayjs(value).format('mm:ss');
      const minutes = time.split(':')[0];
      const seconds = time.split(':')[1];
      return `${minutes}分${seconds}秒`;
    },
    handleRefreshStatus() {
      this.tableData.forEach((item) => {
        this.$set(item.tradeOrderVo, 'orderStatus', item.tradeOrderVo.orderStatus);
      });
    },
    onSearch() {
      // 修改搜索条件时把页码重置为1
      this.currentPage = 1;
      this.getSearchList();
    },
    getParams() {
      let params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      this.orderNo && set(params, 'orderNo', this.orderNo);
      this.orderStatus && set(params, 'orderStatus', Number(this.orderStatus));
      this.goodsId && set(params, 'goodsId', this.goodsId);
      this.goodsName && set(params, 'goodsName', this.goodsName);
      this.logisticNo && set(params, 'logisticNo', this.logisticNo);
      this.orderSettleStatus && set(params, 'settlementStatus', this.orderSettleStatus);
      this.isAllDelivery && set(params, 'isAllDelivery', this.isAllDelivery);
      this.supplyType && set(params, 'supplyType', this.supplyType);
      this.mobile && set(params, 'mobile', this.mobile);
      this.receiverName && set(params, 'receiverName', this.receiverName);
      this.refundStatus && set(params, 'refundStatus', this.refundStatus);
      this.storeName && set(params, 'storeName', this.storeName);
      this.teamId && set(params, 'teamId', this.teamId);
      this.dateRange && this.dateRange.length > 0 && set(params, 'beginTime', this.dateRange[0]);
      this.dateRange && this.dateRange.length > 0 && set(params, 'endTime', this.dateRange[1]);
      this.groupType && set(params, 'groupType', this.groupType);
      this.storeCouponId && set(params, 'storeCouponId', this.storeCouponId);
      params.projectCode = this.projectCode;
      return params;
    },
    // 导出订单明细
    exportTable() {
      let params = this.getParams();

      const { beginTime, endTime } = params;
      if (!beginTime || !endTime) {
        this.$message.error('请选择下单时间');
        return;
      }
      this.$axios({
        method: 'post',
        url: this.$api.order.export,
        data: params,
        responseType: 'blob',
      }).then((res) => {
        downloadFile(res, '订单列表.xls');
      });
    },
    async getSearchList(opt) {
      let params = this.getParams();
      this.loading = true;
      await this.$axios.post(this.$api.order.orderList, params).then((res) => {
        this.tableData = res.data.records;
        // 倒计时结束，刷新列表，更新页面状态
        if (opt === 'refresh') {
          this.handleRefreshStatus();
        }
        // 是否存在待取消的名宿订单，如果有，设置30分钟后结束的时间，解决浏览器tab切换的时候，在切换该页面时，时间误差问题
        const result = this.tableData.some((item) => item.tradeOrderVo?.autoCancelWaitConfirmTime);
        if (result) {
          this.tableData.forEach((item) => {
            const { autoCancelWaitConfirmTime } = item.tradeOrderVo;
            if (autoCancelWaitConfirmTime) {
              const minutes = +autoCancelWaitConfirmTime / 1000 / 60;
              this.$set(item.tradeOrderVo, 'countdownEndTime', dayjs().add(minutes, 'minute').valueOf());
            }
          });
        }
        this.count = Number(res.data.total);
        this.loading = false;
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      });
    },
    resetSearch() {
      this.receiverName = '';
      this.beginTime = '';
      this.endTime = '';
      this.goodsId = '';
      this.goodsName = '';
      this.mobile = '';
      this.orderNo = '';
      this.orderStatus = '';
      this.receiverName = '';
      this.storeName = '';
      this.dateRange = [new Date(new Date().getTime() - 3600 * 1000 * 24 * 30), new Date()];
      this.refundStatus = '';
      this.groupType = '';
      this.logisticNo = '';
      this.storeCouponId = '';
      this.projectCode = '';
      this.teamId = '';
    },
    handleClipboard(event, orderNo) {
      clipboard(orderNo, event);
    },
    handleDetail(type, id) {
      // console.log(type)
      let routeData = {};
      routeData = this.$router.resolve({
        path: '/nb/order/detail',
        query: {
          orderId: id,
        },
      });
      // if (type === 1 || type === 3) {
      //   routeData = this.$router.resolve({
      //     path: '/nb/order/detail',
      //     query: {
      //       orderId: id
      //     }
      //   })
      // } else {
      //   routeData = this.$router.resolve({
      //     path: '/nb/order/homestay/detail',
      //     query: {
      //       orderId: id
      //     }
      //   })
      // }

      window.open(routeData.href, '_blank');
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getSearchList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.isSearch = false;
      this.getSearchList();
    },
    handleChangeAddress(row) {
      if (row.tradeOrderVo.modifyReceiveStatus == 0) {
        this.$message.warning('用户已提交改地址申请，请至改地址申请列表处理');
        return;
      }
      this.$refs.orderAddress.open(row);
    },
    handleAuditAddress(row) {
      this.$router.push({
        path: '/nb/order/address',
        query: {
          orderNo: row.tradeOrderVo.orderNo,
        },
      });
    },
    // 发货
    onDeliverGoods(row) {
      console.log(row);
      this.orderData = row.tradeOrderVo;
      this.showDeliverModel = true;
    },
    // 发货成功
    deliverSuccess() {
      this.getSearchList();
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  --table-width: 1800px;
  background: #fff;
  padding: 20px;
}
.order-header {
  padding: 20px 16px;
  background-color: rgb(240, 242, 248);
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 14px;
  min-width: var(--table-width);
}
.order-body {
  min-width: var(--table-width);
}

.empty {
  width: 100%;
  text-align: center;
  line-height: 60px;
  color: #909399;
  border: 1px solid #ebeef5;
}

.countdown{
  color:red;
}
.order {
  border: 1px solid #ebeef5;
  margin-bottom: 10px;

  .order-id {
    padding: 12px 16px;
    background-color: #f5f7fa;
    display: flex;
    justify-content: space-between;

    span {
      padding-right: 20px;
    }
  }

  .goods-table {
    padding: 12px 16px;
    border-bottom: 1px solid #ebeef5;
    font-size: 14px;
    line-height: 28px;
    word-break: break-word;

    .goods-card {
      min-width: 10px;
      width: 100%;
      display: flex;

      img {
        margin-top: 8px;
        width: 88px;
        height: 88px;
      }

      .content {
        padding: 0 12px;
        line-height: 28px;

        .sku {
          color: #736E6E;
        }
      }
    }

    .fee {
      color: #736E6E;
      line-height: 20px;
    }

    .refund {
      color: #ff0000
    }
  }
}
</style>
